<template>
    <div class="c-SectionThree u-relative u-fit-w" v-bind:class="{ 'is-page-ready': isPageReady, 'is-from-sections': isFromSections, 'is-from-section': isFromSection }">

        <sub-header v-bind:active="isPageReady" v-bind:number="3" v-bind:title="'A Redefinition of the Global Innovation Influence Map'"></sub-header>

        <section class="js-section js-ui-switch js-nav-section c-SectionThree-intro u-relative u-fit-w u-viewport-fit-h u-color--white u-overflow-h" v-bind:style="{ height: minHeight +'px' }">
            <parallax-object v-bind:active="isPageReady" v-bind:ratio="-0.8" class="c-Container u-absolute u-pos-tl u-fit u-flex u-align-items-c | u-align-items-fe@sm">
                <object-fit v-bind:width="1280" v-bind:height="720" v-bind:width-mobile="374" v-bind:height-mobile="664" class="c-SectionThree-intro-video u-bg--blue u-fixed u-pos-tl u-fit">
                    <video-observer class="u-absolute u-pos-tl u-fit">
                        <video-responsive>
                            <video class="js-object-fit js-video u-absolute u-pos-tl u-fit" v-bind:poster="isMobile ? `${publicPath}static/images/three/poster-intro-mobile.jpg` : `${publicPath}static/images/three/poster-intro.jpg`" autoplay loop muted playsinline>
                                <source :src="`${publicPath}static/videos/section3.webm`" type="video/webm">
                                <source :src="`${publicPath}static/videos/section3.mp4`" type="video/mp4">

                                <source :srcset="`${publicPath}static/videos/section3-mobile.webm`" type="video/webm" media="(max-width: 767px)">
                                <source :srcset="`${publicPath}static/videos/section3-mobile.mp4`" type="video/mp4" media="(max-width: 767px)">
                            </video>
                        </video-responsive>
                    </video-observer>
                </object-fit>

                <div class="u-relative u-w11of16 u-offset-l-w3of16 u-force-3d | u-w12of16@lg u-offset-l-w2of16@lg | u-fit-w@sm u-marg-l-0@sm">
                    <h1 class="c-SectionThree-intro-title t-h1">
                        <span class="c-SectionThree-line c-SectionThree-line--0 c-SectionThree-intro-title-line-1 u-block">A Redefinition</span>
                        <span class="c-SectionThree-line c-SectionThree-line--1 c-SectionThree-intro-title-line-2 u-block u-offset-l-w4of11 | u-offset-l-w1of8@sm">of the Global</span>
                        <span class="c-SectionThree-line c-SectionThree-line--2 c-SectionThree-intro-title-line-3 u-hide | u-block@sm">Innovation</span>
                        <span class="c-SectionThree-line c-SectionThree-line--3 c-SectionThree-intro-title-line-4 u-hide | u-block@sm">Influence Map</span>
                    </h1>

                    <div class="c-SectionThree-intro-foot u-relative u-flex u-fit-w">
                        <div class="u-block u-w8of11 u-text-right u-pad-r-lg | u-hide@sm">
                            <span class="c-SectionThree-line c-SectionThree-line--2 c-SectionThree-intro-title-line-3 u-block t-h1">Innovation</span>
                            <span class="c-SectionThree-line c-SectionThree-line--3 c-SectionThree-intro-title-line-4 u-block t-h1">Influence Map</span>
                        </div>

                        <div class="c-SectionThree-intro-desc u-w3of11 | u-w6of8@sm u-offset-l-w1of8@sm">
                            <div class="c-SectionThree-line c-SectionThree-line--2 | c-SectionThree-line-mobile--4">
                                <span class="t-text--xxs u-font-bold u-uppercase u-flex u-align-items-c">
                                    <svg class="c-SectionThree-intro-icon u-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" width="12" height="12">
                                        <path fill="#FFFFFF" d="M10.9 7.9c-.6-.8-1.4-1.5-2.3-1.9.9-.4 1.7-1.1 2.3-1.9.2-.3.4-.5.5-.8v-.2c-.1-.3-.3-.5-.5-.8l-.2-.3-.1.3c-.1.3-.2.5-.4.8C9.3 4.5 7.7 5.4 6 5.4c-1.7 0-3.3-.9-4.2-2.3-.2-.3-.3-.5-.4-.8L1.3 2l-.2.3c-.2.3-.4.5-.6.8v.2c.1.3.3.6.5.8.7.8 1.5 1.5 2.4 1.9-.9.4-1.7 1.1-2.3 1.9-.2.3-.4.5-.6.8v.2c.1.3.3.5.5.8l.3.3.1-.3c.1-.3.2-.5.4-.8.9-1.4 2.5-2.3 4.2-2.3 1.7 0 3.3.9 4.2 2.3.2.3.3.5.4.8l.1.3.2-.3c.2-.3.4-.5.5-.8v-.2c-.1-.3-.3-.5-.5-.8z"/>
                                        <circle fill="#FFFFFF" cx="6" cy="1.2" r="1.2"/>
                                        <circle fill="#FFFFFF" cx="6" cy="10.8" r="1.2"/>
                                    </svg>
                                    <span>section 03</span>
                                </span>
                            </div>

                            <p class="c-SectionThree-line c-SectionThree-line--3 c-SectionThree-intro-text t-text--sm | c-SectionThree-line-mobile--5">Beyond the battle for innovation leadership between the USA and China.</p>
                        </div>
                    </div>
                </div>
                <div class="c-SectionThree-intro-scroll c-Container u-absolute u-pos-bl u-fit-w">
                    <cursor-trigger v-bind:name="'scroll'" class="u-inline-block u-offset-l-w2of16 ">
                        <button v-on:click="onScroll" type="button" class="c-SectionThree-intro-scroll-btn c-ScrollBtn t-btn t-text--xxs u-font-bold">SCROLL</button>
                    </cursor-trigger>
                </div>
            </parallax-object>
        </section>

        <section class="js-section js-ui-switch js-nav-section c-SectionThree-dataOne c-Container u-relative u-bg--white u-overflow-h">
            <h2 class="t-h3 u-w5of16 u-offset-l-w6of16 | u-offset-l-w5of16@md | u-w6of8@sm u-offset-l-w1of8@sm">China closing the gap on the USA</h2>

            <p class="c-SectionThree-dataOne-desc t-h4 u-w7of16 u-offset-l-w2of16 | u-w8of16@md u-offset-l-w1of16@md | u-w14of16@sm u-offset-l-w1of16@sm">Innovation influence and power dynamics are becoming more aligned with flows of investment and capital. This makes international relationships between countries more fragile.</p>

            <cursor-trigger v-bind:name="'drag'" v-bind:active="!hasInteractedGraph" v-bind:class="{ 'is-cursor-drag': !hasInteractedGraph }" class="c-SectionThree-dataOne-graphs u-relative u-fit-w">
                <appear-object v-bind:active="isPageReady">
                    <div v-on="graphHandlers" v-bind:class="{ 'is-active': graphActive === 3 }" class="c-SectionThree-dataOne-graph">
                        <default-slider ref="sliderOne" class="u-select-none">
                            <div class="u-w12of16 u-offset-l-w4of16 u-white-space-nowrap u-vacuum | u-w15of16@sm u-offset-l-w1of16@sm">
                                <div v-for="(continent, i) in graphOne" v-bind:key="i" class="js-item c-SectionThree-dataOne-group u-inline-block u-w5of12 | u-fit-w@sm">
                                    <div v-for="(item, i) in continent.items" v-bind:key="i" class="c-SectionThree-dataOne-bar u-inline-block u-w1of5">
                                        <div class="c-SectionThree-dataOne-bar-inner">
                                            <div class="c-SectionThree-dataOne-bar-color" v-bind:style="{ height: item.value / graphOneMax * 100 + '%' }">
                                                <div class="c-SectionThree-dataOne-bar-color-bg u-absolute u-pos-tl u-fit" v-bind:class="'is-' + item.color"></div>
                                                <p class="u-relative t-text--sm u-text-center u-color--white u-pad-a-sm">{{item.value}}%</p>
                                            </div>
                                        </div>
                                        <p class="c-SectionThree-dataOne-bar-label t-text--xxs u-font-bold u-uppercase u-text-center">{{item.label}}</p>
                                    </div>
                                    <p class="c-SectionThree-dataOne-continent u-w4of5 t-text--sm u-font-bold u-uppercase u-text-center">{{continent.label}}</p>
                                </div>
                            </div>
                        </default-slider>
                    </div>

                    <div v-on:pointerdown="hasInteractedGraph = true" v-bind:class="{ 'is-active': graphActive === 2 }" class="c-SectionThree-dataOne-graph u-absolute u-pos-tl u-fit-w">
                        <default-slider ref="sliderOne" class="u-select-none">
                            <div class="u-w12of16 u-offset-l-w4of16 u-white-space-nowrap u-vacuum | u-w15of16@sm u-offset-l-w1of16@sm">
                                <div v-for="(continent, i) in graphTwo" v-bind:key="i" class="js-item c-SectionThree-dataOne-group u-inline-block u-w5of12 | u-fit-w@sm">
                                    <div v-for="(item, i) in continent.items" v-bind:key="i" class="c-SectionThree-dataOne-bar u-inline-block u-w1of5">
                                        <div class="c-SectionThree-dataOne-bar-inner">
                                            <div class="c-SectionThree-dataOne-bar-color" v-bind:style="{ height: item.value / graphTwoMax * 100 + '%' }">
                                                <div class="c-SectionThree-dataOne-bar-color-bg u-absolute u-pos-tl u-fit" v-bind:class="'is-' + item.color"></div>
                                                <p class="u-relative t-text--sm u-text-center u-color--white u-pad-a-sm">{{item.value}}%</p>
                                            </div>
                                        </div>
                                        <p class="c-SectionThree-dataOne-bar-label t-text--xxs u-font-bold u-uppercase u-text-center">{{item.label}}</p>
                                    </div>
                                    <p class="c-SectionThree-dataOne-continent u-w4of5 t-text--sm u-font-bold u-uppercase u-text-center">{{continent.label}}</p>
                                </div>
                            </div>
                        </default-slider>
                    </div>

                    <div v-on:pointerdown="hasInteractedGraph = true" v-bind:class="{ 'is-active': graphActive === 1 }" class="c-SectionThree-dataOne-graph u-absolute u-pos-tl u-fit-w">
                        <default-slider ref="sliderOne" class="u-select-none">
                            <div class="u-w12of16 u-offset-l-w4of16 u-white-space-nowrap u-vacuum | u-w15of16@sm u-offset-l-w1of16@sm">
                                <div v-for="(continent, i) in graphThree" v-bind:key="i" class="js-item c-SectionThree-dataOne-group u-inline-block u-w5of12 | u-fit-w@sm">
                                    <div v-for="(item, i) in continent.items" v-bind:key="i" class="c-SectionThree-dataOne-bar u-inline-block u-w1of5">
                                        <div class="c-SectionThree-dataOne-bar-inner">
                                            <div class="c-SectionThree-dataOne-bar-color" v-bind:style="{ height: item.value / graphThreeMax * 100 + '%' }">
                                                <div class="c-SectionThree-dataOne-bar-color-bg u-absolute u-pos-tl u-fit" v-bind:class="'is-' + item.color"></div>
                                                <p class="u-relative t-text--sm u-text-center u-color--white u-pad-a-sm">{{item.value}}%</p>
                                            </div>
                                        </div>
                                        <p class="c-SectionThree-dataOne-bar-label t-text--xxs u-font-bold u-uppercase u-text-center">{{item.label}}</p>
                                    </div>
                                    <p class="c-SectionThree-dataOne-continent u-w4of5 t-text--sm u-font-bold u-uppercase u-text-center">{{continent.label}}</p>
                                </div>
                            </div>
                        </default-slider>
                    </div>

                    <div v-on:pointerdown="hasInteractedGraph = true" v-bind:class="{ 'is-active': graphActive === 0 }" class="c-SectionThree-dataOne-graph u-absolute u-pos-tl u-fit-w">
                        <default-slider ref="sliderOne" class="u-select-none">
                            <div class="u-w12of16 u-offset-l-w4of16 u-white-space-nowrap u-vacuum | u-w15of16@sm u-offset-l-w1of16@sm">
                                <div v-for="(continent, i) in graphFour" v-bind:key="i" class="js-item c-SectionThree-dataOne-group u-inline-block u-w5of12 | u-fit-w@sm">
                                    <div v-for="(item, i) in continent.items" v-bind:key="i" class="c-SectionThree-dataOne-bar u-inline-block u-w1of5">
                                        <div class="c-SectionThree-dataOne-bar-inner">
                                            <div class="c-SectionThree-dataOne-bar-color" v-bind:style="{ height: item.value / graphFourMax * 100 + '%' }">
                                                <div class="c-SectionThree-dataOne-bar-color-bg u-absolute u-pos-tl u-fit" v-bind:class="'is-' + item.color"></div>
                                                <p class="u-relative t-text--sm u-text-center u-color--white u-pad-a-sm">{{item.value}}%</p>
                                            </div>
                                        </div>
                                        <p class="c-SectionThree-dataOne-bar-label t-text--xxs u-font-bold u-uppercase u-text-center">{{item.label}}</p>
                                    </div>
                                    <p class="c-SectionThree-dataOne-continent u-w4of5 t-text--sm u-font-bold u-uppercase u-text-center">{{continent.label}}</p>
                                </div>
                            </div>
                        </default-slider>
                    </div>

                </appear-object>

                <div v-bind:class="{ 'is-touch': isTouch }" class="c-Cursor-drag u-absolute u-hide u-align-items-c u-justify-content-c | u-flex@sm">
                    <span class="c-Cursor-drag-label u-relative t-text--sm u-color--black">Drag</span>
                    <div class="c-Cursor-drag-icon u-absolute u-flex u-align-items-c u-justify-content-sb">
                        <span class="c-Cursor-drag-icon-arrow">
                            <span class="c-Cursor-drag-icon-arrow-line"></span>
                        </span>
                        <span class="c-Cursor-drag-icon-arrow">
                            <span class="c-Cursor-drag-icon-arrow-line"></span>
                        </span>
                    </div>
                </div>
            </cursor-trigger>


            <div class="u-flex u-flex-flow-row-wrap">
                <div class="u-w3of16 u-offset-l-w2of16 | u-w4of16@lg | u-w5of16@md u-offset-l-w1of16@md | u-w6of8@sm u-offset-l-w1of8@sm">
                    <p class="c-Switch-legend t-text--sm u-opacity--50 u-italic">Select a year and drag the graph</p>
                </div>
                <div class="u-w7of16 u-offset-l-w1of16 | u-w8of16@md | u-w6of8@sm u-offset-l-w1of8@sm u-marg-t-md@sm u-marg-b-lg@sm">
                    <ul class="t-list u-flex">
                        <li><button v-on:click="graphActive = 0" v-bind:class="{ 'is-active': graphActive === 0 }" type="button" class="c-Switch-btn c-Switch-btn--alt t-btn t-text--sm u-uppercase u-font-bold">2014</button></li>
                        <li><button v-on:click="graphActive = 1" v-bind:class="{ 'is-active': graphActive === 1 }" type="button" class="c-Switch-btn c-Switch-btn--alt t-btn t-text--sm u-uppercase u-font-bold">2016</button></li>
                        <li><button v-on:click="graphActive = 2" v-bind:class="{ 'is-active': graphActive === 2 }" type="button" class="c-Switch-btn c-Switch-btn--alt t-btn t-text--sm u-uppercase u-font-bold">2018</button></li>
                        <li><button v-on:click="graphActive = 3" v-bind:class="{ 'is-active': graphActive === 3 }" type="button" class="c-Switch-btn c-Switch-btn--alt t-btn t-text--sm u-uppercase u-font-bold">2020</button></li>
                    </ul>
                </div>
            </div>

            <div class="u-flex u-flex-flow-row-wrap">
                <div class="u-w3of16 u-offset-l-w2of16 | u-w4of16@lg | u-w5of16@md u-offset-l-w1of16@md | u-w6of8@sm u-offset-l-w1of8@sm">
                    <p class="t-text--sm u-marg-b-sm">A view of which countries Business Executives view as the innovation champion (regional breakdown).</p>

                    <div class="u-relative@sm">
                        <question-btn number="1" question="What is THE country that you consider to be the leading innovation champion?" response="Base: African Business Executives 2020: 302, Asian Business Executives 2020: 751, European Business Executives 2020: 500, Latin American Business Executives 2020: 200, Middle Eastern Business Executives 2020: 404, US Business Executives 2020: 150)" />
                        <share-btn class="u-marg-l-xs" />
                    </div>
                </div>
                <div class="u-w7of16 u-offset-l-w1of16 | u-w8of16@md | u-w14of16@sm u-offset-l-w1of16@sm">
                    <p class="c-SectionThree-dataOne-desc--alt t-h4">In places like Africa and Israel where Chinese investment is high, so too is perception of China becoming the most innovative country, outpacing Japan and gaining on the USA. </p>
                </div>
            </div>
        </section>

        <section class="js-section c-SectionThree-dataTwo c-Container u-relative u-bg--white">
            <div class="u-relative u-fit-w">
                <div class="c-SectionThree-dataTwo-video u-w10of16 u-offset-l-w3of16 | u-w12of16@md u-offset-l-w2of16@md | u-fit-w@sm u-marg-l-0@sm">
                    <video-observer class="c-SectionThree-dataTwo-video-container t-b-radius-lg">
                        <video-responsive>
                            <video class="js-video u-absolute u-pos-tl u-fit" :poster="`${publicPath}static/images/three/video-poster.jpg`" preload="auto" loop muted playsinline>
                                <source :src="`${publicPath}static/videos/three/video.webm`" type="video/webm">
                                <source :src="`${publicPath}static/videos/three/video.mp4`" type="video/mp4">

                                <source :srcset="`${publicPath}static/videos/three/video-mobile.webm`" type="video/webm" media="(max-width: 767px)">
                                <source :srcset="`${publicPath}static/videos/three/video-mobile.mp4`" type="video/mp4" media="(max-width: 767px)">
                            </video>
                        </video-responsive>
                    </video-observer>
                </div>

                <parallax-object v-bind:ratio="0.1" class="c-SectionThree-dataTwo-img u-w5of16 u-offset-l-w2of16 u-absolute u-pos-bl | u-offset-l-w1of16@md | u-w13of16@sm u-offset-l-w1of16@sm">
                    <div class="c-SectionThree-dataTwo-img-inner t-b-radius-md">
                        <img :src="`${publicPath}static/images/three/1.jpg`" alt="" class="u-absolute u-pos-tl u-fit">
                    </div>
                </parallax-object>
            </div>
        </section>

        <section class="js-section js-nav-section c-SectionThree-dataThree c-Container u-relative u-bg--white">
            <h2 class="t-h3 u-w5of16 u-offset-l-w6of16 | u-offset-l-w5of16@md | u-w6of8@sm u-offset-l-w1of8@sm">The battle<br>of the giants</h2>

            <p class="c-SectionThree-dataThree-desc t-h4 u-w7of16 u-offset-l-w2of16 | u-w8of16@md u-offset-l-w1of16@md | u-w14of16@sm u-offset-l-w1of16@sm">The USA and China trust each other overall. However, internal optimism is growing in China, while USA's self-perception of itself is declining.</p>

            <appear-object v-bind:ratio="0.3" v-bind:active="isPageReady" class="c-SectionThree-dataThree-content u-flex u-flex-flow-row-wrap | u-align-items-c@lg">
                <div class="u-w3of16 u-offset-l-w1of16 | u-w6of16@md u-offset-l-w1of16@md | u-w14of16@sm u-offset-l-w1of16@sm">
                    <h3 class="t-h2 u-marg-t-lg | u-marg-t-0@lg">USA</h3>
                    <p class="t-text--sm u-marg-t-lg">USA's self-view and global view of USA as an innovation-conducive environment:</p>

                    <ul class="c-SectionThree-dataThree-lines t-list u-marg-t-sm | u-marg-t-md@sm">
                        <li>
                            <div class="c-SectionThree-dataThree-lines-item">
                                <div class="c-SectionThree-dataThree-lines-item-cyan" style="width: 76%;"></div>
                                <div class="c-SectionThree-dataThree-lines-item-blue" style="width: 72%;"></div>
                            </div>
                            <p class="t-text--xxs u-uppercase u-font-bold">2014:<span class="u-color--blue u-marg-x-xxs">72%</span><span class="u-color--blue-light">76%</span></p>
                        </li>
                        <li class="u-marg-t-xs">
                            <div class="c-SectionThree-dataThree-lines-item">
                                <div class="c-SectionThree-dataThree-lines-item-blue" style="width: 86%;"></div>
                                <div class="c-SectionThree-dataThree-lines-item-cyan" style="width: 77%;"></div>
                            </div>
                            <p class="t-text--xxs u-uppercase u-font-bold">2016:<span class="u-color--blue u-marg-x-xxs">86%</span><span class="u-color--blue-light">77%</span></p>
                        </li>
                        <li class="u-marg-t-xs">
                            <div class="c-SectionThree-dataThree-lines-item">
                                <div class="c-SectionThree-dataThree-lines-item-blue" style="width: 85%;"></div>
                                <div class="c-SectionThree-dataThree-lines-item-cyan" style="width: 76%;"></div>
                            </div>
                            <p class="t-text--xxs u-uppercase u-font-bold">2018:<span class="u-color--blue u-marg-x-xxs">85%</span><span class="u-color--blue-light">76%</span></p>
                        </li>
                        <li class="u-marg-t-xs">
                            <div class="c-SectionThree-dataThree-lines-item">
                                <div class="c-SectionThree-dataThree-lines-item-blue" style="width: 79%;"></div>
                                <div class="c-SectionThree-dataThree-lines-item-cyan" style="width: 73%;"></div>
                            </div>
                            <p class="t-text--xxs u-uppercase u-font-bold">2020:<span class="u-color--blue u-marg-x-xxs">79%</span><span class="u-color--blue-light">73%</span></p>
                        </li>
                    </ul>

                    <ul class="t-list u-marg-t-sm">
                        <li class="t-text--sm"><span class="c-SectionThree-dataThree-legend u-bg--blue"></span>USA’s view of themself</li>
                        <li class="t-text--sm"><span class="c-SectionThree-dataThree-legend u-bg--blue-light"></span>Global view of USA</li>
                    </ul>

                    <p class="t-text--sm u-marg-t-lg">American Business Executives still trust themselves with their innovation plans and assets:</p>

                    <ul class="c-SectionThree-dataThree-lines t-list u-marg-t-sm | u-marg-t-md@sm">
                        <li>
                            <div class="c-SectionThree-dataThree-lines-item">
                                <div class="c-SectionThree-dataThree-lines-item-blue" style="width: 45%;"></div>
                            </div>
                            <p class="t-text--xxs u-uppercase u-font-bold">trust in china:<span class="u-color--blue u-color--blue u-marg-x-xxs">47%</span></p>
                        </li>
                        <li class="u-marg-t-xs">
                            <div class="c-SectionThree-dataThree-lines-item">
                                <div class="c-SectionThree-dataThree-lines-item-blue" style="width: 73%;"></div>
                            </div>
                            <p class="t-text--xxs u-uppercase u-font-bold">trust in USA:<span class="u-color--blue u-color--blue u-marg-x-xxs">76%</span></p>
                        </li>
                    </ul>

                </div>

                <div class="c-SectionThree-dataThree-webgl u-w6of16 u-offset-l-w1of16 | u-fit-w@md u-marg-l-0@md">
                    <div class="c-SectionThree-dataThree-gradient t-b-radius-lg">
                        <!-- <generalelectric-ib2020-giants ref="giant" :baseuri="publicPath" class="u-absolute u-pos-tl u-fit"></generalelectric-ib2020-giants> -->
                        <generalelectric-ib2020-giants ref="giant" :baseuri="publicPath" class="u-absolute u-pos-tl u-fit" transparent></generalelectric-ib2020-giants>
                    </div>

                    <div class="c-SectionThree-dataThree-questions u-flex u-flex-flow-row-wrap u-justify-content-c | u-justify-content-fs@md | u-fit@sm u-relative@sm">
                        <question-btn class="u-marg-r-xs" number="3" question="For each of the following markets, how far would you say that they have developed an Innovation conducive environment?" response="(Top 3 Box) [TRACKED BE] Base: Business executives 2014 [China: 200, USA: 300]; Business executives 2016 [China: 207, USA: 207]; Business executives 2018 [China: 150; USA: 150]; Business executives Jan 2020 [China: 150; USA: 150]; Business executives Sept 2020 [China: 155; USA: 152]" />
                        <question-btn number="4" question="Which countries do you most trust with your company’s innovation plans and assets and which countries do you not trust with your company’s innovation plans and assets?" response="(Top 3 Box) [NEW JAN 2020 BE] Base: Business executives Sept 2020: 1,128" />

                        <div class="u-flex@sm u-relative@sm u-fit-w@sm u-marg-t-xs@sm">
                            <share-btn class="u-marg-l-xs | u-marg-l-0@sm" />
                        </div>
                    </div>
                </div>

                <div class="u-w3of16 u-offset-l-w1of16 | u-w6of16@md u-offset-l-w2of16@md | u-w14of16@sm u-offset-l-w1of16@sm">
                    <h3 class="t-h2 u-marg-t-lg | u-marg-t-0@lg">China</h3>

                    <p class="t-text--sm u-marg-t-lg">China's self-view and global view of China as an innovation-conducive environment:</p>

                    <ul class="c-SectionThree-dataThree-lines t-list u-marg-t-sm | u-marg-t-md@sm">
                        <li>
                            <div class="c-SectionThree-dataThree-lines-item">
                                <div class="c-SectionThree-dataThree-lines-item-cyan" style="width: 50%;"></div>
                                <div class="c-SectionThree-dataThree-lines-item-blue" style="width: 24%;"></div>
                            </div>
                            <p class="t-text--xxs u-uppercase u-font-bold">2014:<span class="u-color--blue u-color--blue u-marg-x-xxs">24%</span><span class="u-color--blue-light">50%</span></p>
                        </li>
                        <li class="u-marg-t-xs">
                            <div class="c-SectionThree-dataThree-lines-item">
                                <div class="c-SectionThree-dataThree-lines-item-blue" style="width: 67%;"></div>
                                <div class="c-SectionThree-dataThree-lines-item-cyan" style="width: 53%;"></div>
                            </div>
                            <p class="t-text--xxs u-uppercase u-font-bold">2016:<span class="u-color--blue u-color--blue u-marg-x-xxs">67%</span><span class="u-color--blue-light">53%</span></p>
                        </li>
                        <li class="u-marg-t-xs">
                            <div class="c-SectionThree-dataThree-lines-item">
                                <div class="c-SectionThree-dataThree-lines-item-blue" style="width: 73%;"></div>
                                <div class="c-SectionThree-dataThree-lines-item-cyan" style="width: 59%;"></div>
                            </div>
                            <p class="t-text--xxs u-uppercase u-font-bold">2018:<span class="u-color--blue u-color--blue u-marg-x-xxs">73%</span><span class="u-color--blue-light">59%</span></p>
                        </li>
                        <li class="u-marg-t-xs">
                            <div class="c-SectionThree-dataThree-lines-item">
                                <div class="c-SectionThree-dataThree-lines-item-blue" style="width: 89%;"></div>
                                <div class="c-SectionThree-dataThree-lines-item-cyan" style="width: 68%;"></div>
                            </div>
                            <p class="t-text--xxs u-uppercase u-font-bold">2020:<span class="u-color--blue u-color--blue u-marg-x-xxs">89%</span><span class="u-color--blue-light">68%</span></p>
                        </li>
                    </ul>

                    <ul class="t-list u-marg-t-sm">
                        <li class="t-text--sm"><span class="c-SectionThree-dataThree-legend u-bg--blue"></span>China’s view of themself</li>
                        <li class="t-text--sm"><span class="c-SectionThree-dataThree-legend u-bg--blue-light"></span>Global view of USA</li>
                    </ul>

                    <p class="t-text--sm u-marg-t-lg">Chinese Business Executives trust themselves more with their innovation plans and assets:</p>

                    <ul class="c-SectionThree-dataThree-lines t-list u-marg-t-sm | u-marg-t-md@sm">
                        <li>
                            <div class="c-SectionThree-dataThree-lines-item">
                                <div class="c-SectionThree-dataThree-lines-item-blue" style="width: 75%;"></div>
                            </div>
                            <p class="t-text--xxs u-uppercase u-font-bold">trust in china:<span class="u-color--blue u-color--blue u-marg-x-xxs">77%</span></p>
                        </li>
                        <li class="u-marg-t-xs">
                            <div class="c-SectionThree-dataThree-lines-item">
                                <div class="c-SectionThree-dataThree-lines-item-blue" style="width: 63%;"></div>
                            </div>
                            <p class="t-text--xxs u-uppercase u-font-bold">trust in USA:<span class="u-color--blue u-color--blue u-marg-x-xxs">62%</span></p>
                        </li>
                    </ul>
                </div>

                <div class="u-w7of16 u-offset-l-w6of16 | u-w7of16@md u-offset-l-w1of16@md | u-w13of16@sm u-offset-l-w1of16@sm">
                    <p class="c-SectionThree-dataThree-text t-h4">Since the onset of COVID-19, China has experienced setbacks in external trust and in internal innovation confidence (-9), while the USA has recovered much of its old confidence (+9).</p>
                </div>
            </appear-object>
        </section>

        <next-section v-bind:url="'/sections/protectionism-continues-to-grow'" v-bind:label="'Section 04'" v-bind:title="'Protectionism Continues&#160;to&#160;Grow'" />

    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex';
    import scrollToY from '@/utilities/scrollToY';
    import getOffsetTop from '@/utilities/getOffsetTop';

    import '@damienmortini/generalelectric-ib2020-giants/src/index.js';

    import CursorTrigger from '@/components/CursorTrigger';
    import ParallaxObject from '@/components/ParallaxObject';
    import ObjectFit from '@/components/ObjectFit';
    import VideoObserver from '@/components/VideoObserver';
    import NextSection from '@/components/NextSection';
    import DefaultSlider from '@/components/DefaultSlider';
    import AppearObject from '@/components/AppearObject';
    import SubHeader from '@/components/SubHeader';
    import QuestionBtn from '@/components/QuestionBtn';
    import VideoResponsive from '@/components/VideoResponsive';
    import ShareBtn from '@/components/ShareBtn';

    export default {
        name: 'Home',

        components: {
            CursorTrigger,
            ParallaxObject,
            ObjectFit,
            VideoObserver,
            NextSection,
            DefaultSlider,
            AppearObject,
            SubHeader,
            QuestionBtn,
            VideoResponsive,
            ShareBtn,
        },

        props: {
            initIndex: { type: Number, default: 0 },
        },

        data() {
            return {
                publicPath: process.env.BASE_URL,
                isPageReady: false,
                isFromSections: false,
                isFromSection: false,

                graphHandlers: {
                    pointerdown: this.setInteractiveGraph,
                    touchstart: this.setInteractiveGraph,
                },

                hasInteractedGraph: false,

                graphActive: 3,

                graphOneMax: 61,
                graphOne: [
                    {
                        label: 'Africa',
                        items: [
                            {
                                value: 38,
                                label: 'China',
                                color: 'cyan',
                            },
                            {
                                value: 19,
                                label: 'USA',
                                color: 'blue',
                            },
                            {
                                value: 11,
                                label: 'Japan',
                                color: 'gray',
                            },
                            {
                                value: 8,
                                label: 'South Africa',
                                color: 'gray',
                            }
                        ]
                    },
                    {
                        label: 'Asia',
                        items: [
                            {
                                value: 30,
                                label: 'USA',
                                color: 'blue',
                            },
                            {
                                value: 20,
                                label: 'Japan',
                                color: 'gray',
                            },
                            {
                                value: 14,
                                label: 'China',
                                color: 'cyan',
                            },
                            {
                                value: 7,
                                label: 'Germany',
                                color: 'gray',
                            }
                        ]
                    },
                    {
                        label: 'Europe',
                        items: [
                            {
                                value: 22,
                                label: 'USA',
                                color: 'blue',
                            },
                            {
                                value: 15,
                                label: 'Germany',
                                color: 'gray',
                            },
                            {
                                value: 13,
                                label: 'China',
                                color: 'cyan',
                            },
                            {
                                value: 13,
                                label: 'Japan',
                                color: 'gray',
                            }
                        ]
                    },
                    {
                        label: 'LATAM',
                        items: [
                            {
                                value: 30,
                                label: 'Japan',
                                color: 'gray',
                            },
                            {
                                value: 17,
                                label: 'china',
                                color: 'cyan',
                            },
                            {
                                value: 16,
                                label: 'USA',
                                color: 'blue',
                            },
                            {
                                value: 11,
                                label: 'mexico',
                                color: 'gray',
                            }
                        ]
                    },
                    {
                        label: 'Middle East',
                        items: [
                            {
                                value: 24,
                                label: 'China',
                                color: 'cyan',
                            },
                            {
                                value: 21,
                                label: 'Japan',
                                color: 'gray',
                            },
                            {
                                value: 16,
                                label: 'USA',
                                color: 'blue',
                            },
                            {
                                value: 7,
                                label: 'UAE',
                                color: 'gray',
                            }
                        ]
                    },
                    {
                        label: 'USA',
                        items: [
                            {
                                value: 61,
                                label: 'USA',
                                color: 'blue',
                            },
                            {
                                value: 15,
                                label: 'China',
                                color: 'cyan',
                            },
                            {
                                value: 11,
                                label: 'Japan',
                                color: 'gray',
                            },
                            {
                                value: 2,
                                label: 'Germany',
                                color: 'gray',
                            }
                        ]
                    }
                ],
                graphTwoMax: 41,
                graphTwo: [
                    {
                        label: 'Africa',
                        items: [
                            {
                                value: 41,
                                label: 'China',
                                color: 'cyan',
                            },
                            {
                                value: 23,
                                label: 'USA',
                                color: 'blue',
                            },
                            {
                                value: 11,
                                label: 'Japan',
                                color: 'gray',
                            },
                            {
                                value: 6,
                                label: 'South Africa',
                                color: 'gray',
                            }
                        ]
                    },
                    {
                        label: 'Asia',
                        items: [
                            {
                                value: 34,
                                label: 'USA',
                                color: 'blue',
                            },
                            {
                                value: 22,
                                label: 'Japan',
                                color: 'gray',
                            },
                            {
                                value: 10,
                                label: 'China',
                                color: 'cyan',
                            },
                            {
                                value: 8,
                                label: 'Germany',
                                color: 'gray',
                            }
                        ]
                    },
                    {
                        label: 'Europe',
                        items: [
                            {
                                value: 22,
                                label: 'USA',
                                color: 'blue',
                            },
                            {
                                value: 19,
                                label: 'Japan',
                                color: 'gray',
                            },
                            {
                                value: 12,
                                label: 'Germany',
                                color: 'gray',
                            },
                            {
                                value: 12,
                                label: 'China',
                                color: 'cyan',
                            }
                        ]
                    },
                    {
                        label: 'LATAM',
                        items: [
                            {
                                value: 33,
                                label: 'Japan',
                                color: 'gray',
                            },
                            {
                                value: 23,
                                label: 'USA',
                                color: 'blue',
                            },
                            {
                                value: 12,
                                label: 'china',
                                color: 'cyan',
                            },
                            {
                                value: 9,
                                label: 'Germany',
                                color: 'gray',
                            }
                        ]
                    },
                    {
                        label: 'Middle East',
                        items: [
                            {
                                value: 28,
                                label: 'Japan',
                                color: 'gray',
                            },
                            {
                                value: 22,
                                label: 'USA',
                                color: 'blue',
                            },
                            {
                                value: 14,
                                label: 'UAE',
                                color: 'gray',
                            },
                            {
                                value: 10,
                                label: 'China',
                                color: 'cyan',
                            }
                        ]
                    },
                    {
                        label: 'USA',
                        items: [
                            {
                                value: 40,
                                label: 'USA',
                                color: 'blue',
                            },
                            {
                                value: 17,
                                label: 'Japan',
                                color: 'gray',
                            },
                            {
                                value: 15,
                                label: 'Canada',
                                color: 'gray',
                            },
                            {
                                value: 13,
                                label: 'China',
                                color: 'cyan',
                            }
                        ]
                    }
                ],
                graphThreeMax: 52,
                graphThree: [
                    {
                        label: 'Africa',
                        items: [
                            {
                                value: 34,
                                label: 'USA',
                                color: 'blue',
                            },
                            {
                                value: 22,
                                label: 'China',
                                color: 'cyan',
                            },
                            {
                                value: 10,
                                label: 'Japan',
                                color: 'gray',
                            },
                            {
                                value: 9,
                                label: 'Germany',
                                color: 'gray',
                            }
                        ]
                    },
                    {
                        label: 'Asia',
                        items: [
                            {
                                value: 33,
                                label: 'USA',
                                color: 'blue',
                            },
                            {
                                value: 20,
                                label: 'Japan',
                                color: 'gray',
                            },
                            {
                                value: 12,
                                label: 'China',
                                color: 'cyan',
                            },
                            {
                                value: 11,
                                label: 'Germany',
                                color: 'gray',
                            }
                        ]
                    },
                    {
                        label: 'Europe',
                        items: [
                            {
                                value: 31,
                                label: 'USA',
                                color: 'blue',
                            },
                            {
                                value: 17,
                                label: 'Japan',
                                color: 'gray',
                            },
                            {
                                value: 12,
                                label: 'Germany',
                                color: 'gray',
                            },
                            {
                                value: 8,
                                label: 'Sweden',
                                color: 'gray',
                            }
                        ]
                    },
                    {
                        label: 'LATAM',
                        items: [
                            {
                                value: 31,
                                label: 'Japan',
                                color: 'gray',
                            },
                            {
                                value: 28,
                                label: 'USA',
                                color: 'blue',
                            },
                            {
                                value: 10,
                                label: 'china',
                                color: 'cyan',
                            },
                            {
                                value: 9,
                                label: 'Germany',
                                color: 'gray',
                            }
                        ]
                    },
                    {
                        label: 'Middle East',
                        items: [
                            {
                                value: 27,
                                label: 'USA',
                                color: 'blue',
                            },
                            {
                                value: 13,
                                label: 'Japan',
                                color: 'gray',
                            },
                            {
                                value: 11,
                                label: 'Germany',
                                color: 'gray',
                            },
                            {
                                value: 11,
                                label: 'UAE',
                                color: 'gray',
                            }
                        ]
                    },
                    {
                        label: 'USA',
                        items: [
                            {
                                value: 52,
                                label: 'USA',
                                color: 'blue',
                            },
                            {
                                value: 8,
                                label: 'Japan',
                                color: 'gray',
                            },
                            {
                                value: 6,
                                label: 'China',
                                color: 'cyan',
                            },
                            {
                                value: 6,
                                label: 'Germany',
                                color: 'gray',
                            }
                        ]
                    }
                ],
                graphFourMax: 64,
                graphFour: [
                    {
                        label: 'Africa',
                        items: [
                            {
                                value: 29,
                                label: 'USA',
                                color: 'blue',
                            },
                            {
                                value: 21,
                                label: 'China',
                                color: 'cyan',
                            },
                            {
                                value: 16,
                                label: 'Germany',
                                color: 'gray',
                            },
                            {
                                value: 11,
                                label: 'Japan',
                                color: 'gray',
                            }
                        ]
                    },
                    {
                        label: 'Asia',
                        items: [
                            {
                                value: 44,
                                label: 'USA',
                                color: 'blue',
                            },
                            {
                                value: 14,
                                label: 'Japan',
                                color: 'gray',
                            },
                            {
                                value: 13,
                                label: 'Germany',
                                color: 'gray',
                            },
                            {
                                value: 7,
                                label: 'China',
                                color: 'cyan',
                            }
                        ]
                    },
                    {
                        label: 'Europe',
                        items: [
                            {
                                value: 34,
                                label: 'USA',
                                color: 'blue',
                            },
                            {
                                value: 19,
                                label: 'Germany',
                                color: 'gray',
                            },
                            {
                                value: 9,
                                label: 'Japan',
                                color: 'gray',
                            },
                            {
                                value: 9,
                                label: 'China',
                                color: 'cyan',
                            }
                        ]
                    },
                    {
                        label: 'LATAM',
                        items: [
                            {
                                value: 24,
                                label: 'USA',
                                color: 'blue',
                            },
                            {
                                value: 22,
                                label: 'Japan',
                                color: 'gray',
                            },
                            {
                                value: 22,
                                label: 'Germany',
                                color: 'gray',
                            },
                            {
                                value: 13,
                                label: 'china',
                                color: 'cyan',
                            }
                        ]
                    },
                    {
                        label: 'Middle East',
                        items: [
                            {
                                value: 25,
                                label: 'USA',
                                color: 'blue',
                            },
                            {
                                value: 13,
                                label: 'Germany',
                                color: 'gray',
                            },
                            {
                                value: 11,
                                label: 'Japan',
                                color: 'gray',
                            },
                            {
                                value: 7,
                                label: 'China',
                                color: 'cyan',
                            }
                        ]
                    },
                    {
                        label: 'USA',
                        items: [
                            {
                                value: 64,
                                label: 'USA',
                                color: 'blue',
                            },
                            {
                                value: 10,
                                label: 'Japan',
                                color: 'gray',
                            },
                            {
                                value: 9,
                                label: 'Germany',
                                color: 'gray',
                            },
                            {
                                value: 5,
                                label: 'China',
                                color: 'cyan',
                            }
                        ]
                    }
                ],
            };
        },

        computed: {
            ...mapState('window', [
                'minHeight',
                'isTouch'
            ]),

            ...mapGetters('window', [
                'isMobile',
                'halfHeight'
            ]),
        },

        watch: {
            graphActive: 'onGraphChange',
        },

        beforeRouteEnter (to, from, next) {
            next((vm) => {
                if(from.meta.type === 'section') {
                    vm.isFromSection = true;
                }

                if (from.name === 'Sections') {
                    vm.isFromSections = true;
                }
            });
        },

        mounted() {
            this.setCurrentNav(30);
            this.$eventHub.$on('page:ready', this.onPageReady);

            this.$eventHub.$on('resize', this.resize);
            this.$eventHub.$on('update', this.update);
            this.$eventHub.$on('route:scroll', this.onRouteScroll);

            this.$nextTick(() => {
                this.$eventHub.$emit('page:enter');

                this.$navSections = Array.from(this.$el.querySelectorAll('.js-nav-section'));
            });
        },

        beforeDestroy() {
            this.$eventHub.$off('page:ready', this.onPageReady);

            this.$eventHub.$off('resize', this.resize);
            this.$eventHub.$off('update', this.update);
            this.$eventHub.$off('route:scroll', this.onRouteScroll);
        },

        methods: {
            ...mapActions('main', [
                'setCurrentNav'
            ]),

            resize() {
                if (!this.$navSections) return;

                this.cuePoints = [];

                this.$navSections.forEach((el) => {
                    this.cuePoints.push(getOffsetTop(el));
                });
            },

            update() {
                if (!this.cuePoints) return;

                this.cuePoints.forEach((offset, i) => {
                    let nextCuePoints = this.cuePoints[i+1] ? this.cuePoints[i+1] : 99999999;

                    if (this.$root.smoothScroll > this.cuePoints[i] - this.halfHeight && this.$root.smoothScroll < nextCuePoints - this.halfHeight ) {
                        this.setCurrentNav(30+i);
                    }
                });
            },

            onScroll() {
                scrollToY(this.$root.scrollTop + this.minHeight);
            },

            onPageReady() {
                this.isPageReady = true;

                if (this.initIndex) {
                    this.cuePoints[this.initIndex];

                    const offset = this.initIndex < 2 ? 0 : 160;
                    scrollToY(this.cuePoints[this.initIndex] - offset);
                }

                setTimeout(() => {
                    this.$eventHub.$emit('webgl:pause');
                }, 800);
            },

            onRouteScroll(index) {
                if (!this.isPageReady) return;

                const offset = index < 2 ? 0 : 160;
                scrollToY(this.cuePoints[index] - offset);
            },

            onGraphChange() {
                if (this.graphActive !== 0) {
                    this.$refs.sliderOne.goToItem(0);
                }
            },

            setInteractiveGraph() {
                this.hasInteractedGraph = true;
            }
        }
    }
</script>

<style lang="stylus">
    .c-SectionThree {
        &-line {
            opacity 0
            transform translateY(80px) translateZ(0)
            will-change transform, opacity

            .is-page-ready & {
                opacity 1
                transform translateZ(0)
            }

            for n in 0..4 {
                &--{n} {
                     transition transform 0.8s $ease-out-quart (n * 0.1s), opacity 0.8s $ease-out-quart (n * 0.1s)
                }
            }

            .is-from-sections & {
                opacity 1
                transform translateZ(0)
                transition none;
            }
        }

        &-line-mobile {
            @media $breakpoints.sm {
                for n in 0..5 {
                    &--{n} {
                         transition transform 0.8s $ease-out-quart (n * 0.1s), opacity 0.8s $ease-out-quart (n * 0.1s)
                    }
                }
            }
        }

        &-intro {
            // z-index 0

            &-video {
                opacity 0
                transform translateZ(0)
                transition opacity 0.8s $ease-out-quart
                will-change opacity

                .is-page-ready & {
                    opacity 1
                }

                .is-from-section & {
                    opacity 1
                    transition none
                }
            }

            &-title {
                &-line-2 {
                    @media $breakpoints.sm {
                        white-space nowrap
                    }
                }

                &-line-3 {
                    @media $breakpoints.sm {
                        margin-top calc(65 * 100vh / 1125)
                    }
                }
            }

            &-foot {
                margin-top 110px

                @media $breakpoints.lg {
                    margin-top calc(110 * 100vh / 1125)
                }

                @media $breakpoints.sm {
                    margin-top 0
                }
            }

            &-desc {
                margin-top 25px

                @media $breakpoints.sm {
                    margin-top 75px
                    margin-bottom 95px
                }

                @media $breakpoints.smh {
                    margin-top 40px
                }
            }

            &-icon {
                margin-top -4px
                margin-right 12px
            }

            &-text {
                margin-top 20px
            }

            &-scroll {
                margin-bottom 90px

                @media $breakpoints.sm {
                    margin-bottom 42px
                }

                &-btn {
                    opacity 0
                    transform translateZ(0)
                    transition opacity 0.8s $ease-out-quart
                    will-change opacity

                    .is-page-ready & {
                        opacity 1
                    }
                }
            }
        }

        &-dataOne {
            // z-index 1
            padding-top 355px
            padding-bottom 255px

            @media $breakpoints.sm {
                padding-top 270px
                padding-bottom 220px
            }

            &-desc {
                padding-top 145px
                padding-bottom 110px

                &--alt {
                    padding-top 105px
                    padding-bottom 0

                    @media $breakpoints.sm {
                        padding-top 135px
                    }
                }
            }

            &-graphs {
                // margin-top 135px
                margin-bottom 75px

                @media $breakpoints.sm {
                    margin-bottom 50px
                }
            }

            &-graph {
                opacity 0
                visibility hidden
                transform translateZ(0)
                will-change opacity, visibility

                transition none

                &.is-active {
                    opacity 1
                    visibility visible

                    transition opacity 0.8s $ease-out-quart, visibility 0.8s $ease-out-quart
                }
            }

            &-continent {
                margin-top 60px
                letter-spacing 0.15em
            }

            &-group {
                overflow hidden
                transform translateZ(0)
                will-change transform

                min-width 344px

                @media $breakpoints.sm {
                    min-width 324px
                }
            }

            &-bar {
                overflow hidden
                position relative
                padding 0 3px
                min-width 80px

                @media $breakpoints.sm {
                    min-width 75px
                    // height 450px
                }

                for n in (1..13) {
                    &:nth-child({n}) {
                        .c-SectionThree-dataOne-bar-color-bg {
                            .is-active & {
                                .is-appear & {
                                    transition transform 0.8s $ease-out-quart (n * 0.05s)
                                }
                            }
                        }
                    }
                }

                &-inner {
                    height 400px
                    width 100%
                    position relative
                    display inline-block
                    vertical-align bottom
                }

                &-color {
                    position absolute
                    bottom 0
                    left 0
                    width 100%
                    height 100%

                    &-bg {
                        border-radius 4px
                        box-shadow: inset 0px 1px 0px 1px rgba(white, 0.5)
                        transform-origin bottom
                        transform scaleY(0) translateZ(0)
                        transition transform 0s $ease-out-quart 0.4s
                        will-change transform

                        .is-active & {
                            .is-appear & {
                                transform scaleY(1) translateZ(0)
                            }
                        }

                        &.is-blue {
                            background linear-gradient(to bottom, #0060b4, #0ea2df, #FFFFFF);
                        }

                        &.is-cyan {
                            background linear-gradient(to bottom, #0eb5df, #86daef, #FFFFFF);
                        }

                        &.is-gray {
                            background linear-gradient(to bottom, #c0c0c0, #FFFFFF);
                        }
                    }

                    .u-pad-a-sm {
                        transform translateZ(0)
                        will-change transform
                    }

                    .t-text--xxs {
                        letter-spacing 0.05em
                    }
                }

                &-label {
                    margin-top 40px
                    letter-spacing 0.05em
                }
            }

            &-legend {
                width 10px
                height 8px
                border-radius 2px
                margin-right 10px
                display inline-block
                vertical-align middle
            }
        }

        &-dataTwo {
            padding-bottom 255px

            @media $breakpoints.sm {
                padding-bottom 215px
            }

            &-video {
                padding-bottom 65px

                @media $breakpoints.sm {
                    padding-bottom 112px
                }

                &-container {
                    aspect-ratio(16, 9)
                    overflow hidden
                    // background $colors['black'];

                    @media $breakpoints.sm {
                        aspect-ratio(374, 664)
                    }
                }
            }

            &-img {
                margin-right 4.168%

                @media $breakpoints.sm {
                    margin-right 0
                }

                &-inner {
                    aspect-ratio(892, 600)
                    overflow hidden
                }
            }
        }

        &-dataThree {
            padding-bottom 320px

            @media $breakpoints.sm {
                padding-bottom 270px
            }

            &-desc {
                padding-top 145px
                padding-bottom 155px
            }

            &-webgl {
                @media $breakpoints.md {
                    order -1
                    margin-bottom: 60px;
                }

                @media $breakpoints.sm {
                    order inherit
                    margin-bottom 0
                    padding 115px 0
                }
            }

            &-lines {
                li {
                    margin-bottom 5px
                }

                .t-text--xxs {
                    letter-spacing 0.05em
                }

                &-item {
                    overflow hidden
                    transform translateZ(0)
                    position relative
                    height 4px
                    width 100%
                    background $colors['gray'];
                    margin-bottom 5px

                    &-blue, &-cyan {
                        position absolute
                        top 0
                        left 0
                        width 100%
                        height 100%

                        will-change transform
                        transform-origin left
                        transform scaleX(0) translateZ(0)
                        transition transform 0.8s $ease-out-quart

                        .is-appear & {
                            transform scaleX(1) translateZ(0)
                        }
                    }

                    &-blue {
                        background $colors['blue'];
                    }

                    &-cyan {
                        background $colors['blue-light'];
                    }
                }
            }

            &-text {
                padding 0 6.25%
                margin-top 160px

                @media $breakpoints.md {
                    padding: 0
                    margin-top 150px
                }
            }

            &-legend {
                position relative
                top -2px
                width 10px
                height 8px
                border-radius 2px
                margin-right 10px
                display inline-block
                vertical-align middle
            }

            &-gradient {
                aspect-ratio(458, 640)
                overflow hidden

                @media $breakpoints.md {
                    aspect-ratio(16, 9)
                }

                @media $breakpoints.sm {
                    aspect-ratio(458, 640)
                }

                // backdrop-filter: blur(7px);
                background linear-gradient(to bottom, #0060b4, rgba(#0eb5df, 0.1));
            }

            &-questions {
                margin-top 25px
            }
        }
    }
</style>